<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
            </search>
        </div>
        <div class="bind-sum">

        </div>
        <div class="mt-3">
            <my-table :data="tableData" :fields="showFields">
                <el-table-column slot="operation" fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                                                <el-button
                                                    size="mini"
                                                    v-if="scope.row.hardware_no"
                                                    @click="bindData(scope.$index,scope.row)" v-access="{url:baseUrl,method:'POST'}"
                                                    icon="el-icon-edit"></el-button>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <form-dialog @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import * as XLSX from 'xlsx';
import search from "@/components/common/search";
import MyTable from "@/components/common/my-table";
import countTo from "vue-count-to";
import {formatTime, reStoreKeyValue} from "@/utils/tools";

export default {
    name: "appSms",
    components: {
        search, MyTable
    },
    created() {

    },
    mounted() {
    },
    data: function () {
        return {
            baseUrl: '/bigmap/pay/gisSearch',
            page: {
                search: {},
                sort: {},
                total: 1,
                pageSize: 15,
                pageNumber: 1,
            },
            sum: {},
            subscribe: [],
            userGroup: [],
            tableData: [],
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            return F.search('gisBind', 'search');
        },
        showFields: function () {
            return F.show('gisBind', 'show');
        },
    },
    methods: {
        condition: function (search) {
            // search={
            //     title:'199210909177'
            // }
            this.page.search = search;
            this.search();
        },
        search: function () {
            if (!this.page.search||!this.page.search.title) return;
            this.$app.post(this.baseUrl, {
                value: this.page.search.title,
            }).then(data => {
                this.tableData = data.data.map(v=>{
                    if (!v.bindData) v.bindData={};
                    if (!v.userData) v.userData={};
                    // product_type:{title:'产品类型',type:'dict_key',dict_key:'product_type'},
                    // username: {title: '用户名',},
                    // nickname:{title:'昵称'},
                    // member:{title:'会员等级'},
                    // member_time:{title:'会员到期时间'},
                    // phone: {title: '手机号'},
                    // create_time: {title: '绑定时
                    return {bind:v.bind,hardware_no:v.hardware_no,phone:v.userData.phone,create_time:v.bindData.create_time,username:v.userData.username,member:v.userData.member,member_time:v.userData.member_time,nickname:v.userData.nickname,};
                });
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        submit: function (data) {
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'binData':
                    this.$app.put(this.baseUrl, data.data).then(adData => {
                        this.$message.success('保存成功!');
                        this.form.show = false;
                        this.search();
                        // this.tableData.splice(this.index, 1, adData.data);
                    });
                    break;
                case 'addServer':
                    this.$axios.post(this.baseUrl, data.data).then(userData => {
                        this.page.total++;
                        this.form.show = false;
                        this.$message.success('创建成功!');
                        this.tableData.unshift(userData.data);
                    });
                    break;
            }
        },
        bindData: function (index) {
            let form = F.get('gisBind', 'bind_add', this.tableData[index]);
            this.actionName = 'binData';
            this.form = form;
            this.index = index;
        },
        addServer: function () {
            let form = F.get('appAd', 'add');
            this.actionName = 'addServer';
            this.form = form;
        },
        deleteServer: function (index, id) {
            this.$confirm('你真的要删除这个订阅吗?', '操作确认').then(() => {
                this.$axios.delete(this.baseUrl, {data: {id}}).then(() => {
                    this.tableData.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.pay-sum {
    display: flex;

    .sum-item {
        flex: 1;
        margin: 10px;
        display: flex;
        height: 50px;
        border-radius: 5px;
        color: #fff;
        background-color: #45bbe0;

        &.danger {
            background-color: rgba(122, 54, 60, 0.85);
        }

        .title {
            flex-shrink: 0;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        .sum {
            display: flex;
            align-items: center;
            height: 100%;
            font-weight: bold;
            justify-content: center;
            font-size: 18px;
        }
    }
}
</style>
